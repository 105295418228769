import router from "@/router";
import store from "@/stores/store";
import { mapActions, mapState } from "vuex";
import { Roles } from "@/_helpers/Role";

export default {
  data() {
    return {
      whiteLabelSettings: ["powered_by", "branding"],
    };
  },
  methods: {
    ...mapActions("configuration", ["getLicenseTerms"]),

    isSuperAdmin() {
      return this.permitted("SuperAdmin");
    },

    canMask() {
      return this.permitted("Companies.Masquerade");
    },

    allowed(route, roles = null) {
      if (roles == "all")
        // TODO: This is a hack fix this
        return true;

      var role = store.state.users.currentUser.role;

      var allowed = false;

      if (route !== null) {
        if (route.charAt(0) === "/") {
          route = route.substring(1, route.length);
        }

        var routeArr = route.split("/");

        if (routeArr.length > 0) {
          router.options.routes.forEach((e) => {
            if (e.path === "/" + routeArr[0]) {
              if (e.children) {
                e.children.forEach((c) => {
                  if (c.path === routeArr[1]) {
                    if (c.meta) {
                      if (!c.meta.authorize) allowed = true;

                      allowed = c.meta.authorize.includes(role);
                    }
                  }
                });
              }
            }
          });
        }
      } else if (roles !== null) {
        allowed = roles.includes(role);
      }

      return allowed;
    },

    // Check if the current state company owns the requested resource
    owns(oid) {
      var cmpId = store.getters["users/companyId"];
      if (cmpId == null) return false;

      return oid == cmpId;
    },

    permitted(permission, prefix = "Frontend.SO.") {
      if (permission == "ALL") return true;

      var fullPermission = prefix + permission;
      var result = store.getters["users/permissions"].filter(
        (e) => e.resourceIdentifier == fullPermission
      );
      return result == null || result.length <= 0 ? false : true;
    },

    // Check if the user is allowed to be edited by the current user
    allowedToEdit(role, targetRole) {
      let allowed = false;

      if (role === Roles.Admin || role === Roles.SuperAdmin) {
        allowed = targetRole !== Roles.SuperAdmin;
      }

      if (role === Roles.CompanyAdmin) {
        allowed =
          targetRole !== Roles.Admin &&
          targetRole !== Roles.SuperAdmin &&
          targetRole !== Roles.CompanyAdmin;
      }

      return allowed;
    },

    // Returns true if access is restricted to setting due to white labeling not being included in the license
    limitedToWhiteLabel(key) {
      if (this.license.WHITE_LABEL) return false;

      return this.whiteLabelSettings.includes(key);
    },

    whiteLabelActive() {
      return this.license.WHITE_LABEL;
    },
  },

  computed: {
    ...mapState("configuration", ["userSettings", "license"]),

    whiteLabel() {
      if (
        this.license.WHITE_LABEL == undefined ||
        this.license.WHITE_LABEL == null
      )
        return false;

      return this.license.WHITE_LABEL.toLowerCase() == "true";
    },
  },
};
