import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";

const ScriptRepository = RepositoryFactory.get("script");

export const scripts = {
  namespaced: true,
  state: {
    currentScript: {},
    scripts: [],
    validScript: true,
    error: null,
    status: {
      loading: false,
    },
  },

  actions: {
    async getScripts({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ScriptRepository.getScripts(
        user,
        rootState.users.userCompany.companyId
      )
        .then((scripts) => {
          commit("GET_SCRIPTS", scripts.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getScript({ dispatch, commit, rootState }, { scriptId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ScriptRepository.getScript(user, scriptId)
        .then((script) => {
          commit("GET_CURRENT", script.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async create({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ScriptRepository.create(
        user,
        rootState.users.userCompany.companyId,
        payload
      )
        .then(() => {
          commit("LOADING", false);
          dispatch("getScripts");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },
    async edit({ dispatch, commit, rootState }, { scriptId, payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      await ScriptRepository.put(user, scriptId, payload)
        .then(() => {
          commit("LOADING", false);
          dispatch("getScripts");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async delete({ dispatch, commit, rootState }, { scriptId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await ScriptRepository.delete(user, scriptId)
        .then(() => {
          commit("LOADING", false);
          dispatch("getScripts");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async execute({ dispatch, commit, rootState }, { scriptId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      await ScriptRepository.execute(user, scriptId)
        .then(() => {
          commit("LOADING", false);
          dispatch("getScripts");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async test({ commit, rootState }, { payload }) {
      commit("LOADING", true);

      var companyId = rootState.users.userCompany.companyId;

      var user = rootState.users.currentUser;

      await ScriptRepository.test(user, payload, companyId)
        .then(() => {
          commit("SET_VALIDSCRIPT", { valid: true });
          commit("LOADING", false);
        })
        .catch((res) => {
          commit("SET_VALIDSCRIPT", { valid: false, msg: res.response.data });
          commit("LOADING", false);
        });
    },

    async setCurrentScript({ commit }, script) {
      commit("GET_CURRENT", script);
    },

    async resetValidScript({ commit }) {
      commit("SET_VALIDSCRIPT", { valid: true });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    GET_SCRIPTS(state, scripts) {
      state.scripts = scripts;
    },

    GET_CURRENT(state, script) {
      state.currentScript = script;
    },

    SET_VALIDSCRIPT(state, content) {
      if (!content.valid) {
        state.error = content.msg;
      }

      state.validScript = content.valid;
    },
  },
};
