import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/tag";

export default {
  get(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/tagdata`, config);
  },

  getKeys(user, company) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/tagdata/keys/${company}`, config);
  },

  getTrendDataForTag(user, tagId, tagKey, metrics) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(
      `${resource}/${tagId}/trend/${tagKey}${metrics}`,
      config
    );
  },

  getCurrentTagdata(user, tagId, tagKey) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/latest/${tagId}/${tagKey}`, config);
  },

  getCurrentsForDevice(user, deveui) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${deveui}/currents`, config);
  },
};
