import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";

const CompanyRepository = RepositoryFactory.get("companies");

export const companies = {
  namespaced: true,
  state: {
    company: {},
    companies: [],
    shared: {},
    companyUsers: [],
    companyApps: [],
    latestActivity: {},
    status: {
      loading: false,
      loggedIn: false,
    },
  },

  actions: {
    async getCompanyUsers({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      await CompanyRepository.getCompanyUsers(user, company.companyId).then(
        (users) => {
          commit("GET_COMPANY_USERS", users.data);
          commit("LOADING", false);
        },
        (error) => {
          dispatch("alert/error", error, { root: true });
          commit("LOADING", false);
        }
      );

      commit("LOADING", false);
    },

    async getCompanyApplications({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      await CompanyRepository.getCompanyApplications(user, company.companyId)
        .then((applications) => {
          commit("GET_COMPANY_APPLICATION", applications.data);
          commit("LOADING", false);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    // Get all companies
    async getAll({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await CompanyRepository.getAll(user)
        .then((companies) => {
          commit("GET_COMPANIES", companies.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      commit("LOADING", false);
    },

    async getById({ commit, dispatch, rootState }, { companyId }) {
      commit("LOADING", true);
      var user = rootState.users.currentUser;

      await CompanyRepository.getCompanyById(user, companyId)
        .then((company) => {
          commit("EDIT_COMPANY", company.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
      commit("LOADING", false);
    },

    // Edit a company
    async editCompany({ dispatch, commit, rootState }, { company }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      var companyId = rootState.users.userCompany.companyId;

      await CompanyRepository.edit(user, companyId, company).then(
        (company) => {
          commit("EDIT_COMPANY", company.data);
          dispatch(
            "languages/setLanguage",
            rootState.users.userCompany.country,
            {
              root: true,
            }
          );
          dispatch("alert/success", i18n.t("common.updated"), { root: true });
          commit("LOADING", false);
        },
        (res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        }
      );
    },

    // Create a company
    async createCompany({ dispatch, commit, rootState }, { company }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await CompanyRepository.create(user, company).then(
        (company) => {
          commit("CREATE_COMPANY", company);

          dispatch("alert/success", i18n.t("common.created"), { root: true });
          commit("LOADING", false);
        },
        (res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        }
      );
    },

    async createUser({ dispatch, commit, rootState }, payload) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await CompanyRepository.createUser(
        user,
        rootState.users.userCompany.companyId,
        payload
      )
        .then(() => {
          dispatch("alert/success", i18n.t("common.created"), { root: true });
          dispatch("getCompanyUsers");
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },

    async postImage({ dispatch, commit, rootState }, { image }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await CompanyRepository.postImage(
        user,
        rootState.users.userCompany.companyId,
        image
      )
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async deleteImage({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await CompanyRepository.deleteImage(
        user,
        rootState.users.userCompany.companyId
      )
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async getLatestActivity({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await CompanyRepository.latestActivity(
        user,
        rootState.users.userCompany.companyId
      )
        .then((activity) => {
          commit("LOADING", false);
          commit("SET_LATEST_ACTIVITY", activity.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async getBatteryStatuses({ commit, dispatch, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await CompanyRepository.getBatteryStatuses(
        user,
        rootState.users.userCompany.companyId
      )
        .then((statuses) => {
          commit("LOADING", false);
          dispatch("tag/setBatteryStatuses", statuses.data, { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async getShared({ commit, rootState }) {
      await CompanyRepository.getShared(rootState.users.currentUser)
        .then((d) => commit("GET_SHARED", d.data))
        .catch((d) => {
          console.error(d);
        });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    SET_LATEST_ACTIVITY(state, activity) {
      state.latestActivity = activity;
    },

    GET_COMPANIES(state, companies) {
      state.companies = companies;
    },

    GET_COMPANY_APPLICATION(state, applications) {
      state.companyApps = applications;
    },

    GET_COMPANY_USERS(state, users) {
      state.companyUsers = users;
    },

    GET_SHARED(state, shared) {
      state.shared = shared;
    },

    CREATE_COMPANY(state, company) {
      state.company = company;
    },

    EDIT_COMPANY(state, company) {
      state.company = company;
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        company: {},
        companies: [],
        companyUsers: [],
        companyApps: [],
        status: {
          loading: false,
          loggedIn: false,
        },
      };
    },
  },

  getters: {
    // eslint-disable-next-line
    getSharedName: (state, getters, rootState, rootGetters) => (id) => {
      if (rootGetters["users/companyId"] == id)
        return rootGetters["users/companyName"];
      return state.shared[id];
    },
  },
};
